import React, { useRef, useState } from "react";
// bootstrap
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
// email js
import emailjs from "@emailjs/browser";
// images
import logo from "../img/Danijelaa.png";
// Styled
import styled from "styled-components";
import Footer from "../components/Footer";

const Upis = () => {
  const form = useRef();
  const [status, setStatus] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setStatus("sending");

    emailjs
      .sendForm("inlingua", "inlingua", form.current, {
        publicKey: "9yoYWIYeOD2TqTfWp",
      })
      .then(
        () => {
          setStatus("sent");
          setTimeout(2000);
          form.current.reset();
        },
        (error) => {
          setStatus("error");
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <StyledUpis>
      <Container>
        <h1 className="text-center mt-5">Online forma za upis.</h1>
        <h3 className="text-center">
          Popunite formu za upis sa informacijama o Vama ili Vašem djetetu i
          kontaktiraćemo vas ubrzo.
        </h3>
        <Row>
          <Col md={6}>
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group controlId="firstName" className="mt-3">
                <Form.Label>Ime i Prezime</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Unesite Vaše ime i prezime."
                  name="ime"
                  required
                />
              </Form.Group>
              <Form.Group controlId="email" className="mt-3">
                <Form.Label>Email.</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Unesite Vaš email."
                  name="email"
                  required
                />
              </Form.Group>
              <Form.Group controlId="phone" className="mt-3">
                <Form.Label>Broj Telefona</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Unesite Vaš broj telefona (+387)."
                  name="telefon"
                  required
                />
              </Form.Group>
              <Form.Group controlId="age" className="mt-3">
                <Form.Label>Godine</Form.Label>
                <Form.Control
                  type="number"
                  name="godine"
                  placeholder="Vaše godine ili godine djeteta."
                  min="0"
                  max="120"
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="language" className="mt-3">
                <Form.Label>Jezik</Form.Label>
                <Form.Control as="select" name="jezik" required>
                  <option value="">Izaberite jezik.</option>
                  <option value="Engleski">Engleski</option>
                  <option value="Njemački">Njemački</option>
                  <option value="Italijanski">Italijanski</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="additionalQuestions" className="mt-3">
                <Form.Label>Dodatne informacije.</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Ukoliko imate dodatne informacije ili pitanja napisite ih ovde."
                  name="dodatnaPitanja"
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="my-3">
                Pošalji.
              </Button>
              {status === "sent" && (
                <Button variant="success" className="ml-3" disabled>
                  Uspješno poslato.
                </Button>
              )}
              {status === "error" && (
                <Button variant="danger" className="" disabled>
                  Greška.
                </Button>
              )}
            </Form>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <Image src={logo} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </StyledUpis>
  );
};

const StyledUpis = styled.div`
  img {
    max-height: 800px;
    max-width: 100%;
  }
`;

export default Upis;
